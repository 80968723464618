.subject-icon {
  height: 18px !important;
  width: 18px !important;
  margin-right: 6px;
}

@media (max-width: 576px) {
  .lg-avatar {
    height: 60px !important;
    width: 60px !important;
  }
}

.search-container {
  position: relative;
  display: flex;
  background-color: transparent;
  flex-direction: row;
  width: 100%;
  height: 48px;
  margin-left: 8px;
}

.search-container > div.MuiInputBase-root {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.search-icon-container {
  position: absolute;
  right: 60px;
  height: 48px;
  display: flex;
  align-items: center;
}
