.title {
  flex-grow: 1;
  display: block;
}

ul {
  padding-top: 0 !important;
}

.outer-container {
  top: 64px;
  bottom: 0;
  left: 81px;
  right: 8px;
  position: fixed;
}

.opened-outer-container {
  top: 64px;
  bottom: 0;
  left: 240px;
  right: 8px;
  position: fixed;
}

@media (max-width: 576px) {
  .outer-container {
    top: 56px;
    left: 65px;
    position: fixed;
  }

  .opened-outer-container {
    top: 56px;
    left: 240px;
    position: fixed;
  }
}

.inner-container {
  position: relative;
  z-index: 99;
}

.avatar-container {
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.avatar-container > .MuiAvatar-root {
  height: 80px;
  width: 80px;
}