#practice-mode-timer svg {
    fill: #000 !important;
    margin-right: 12px;
}
#handover-note div {
    cursor: pointer;
}
.collapsed-note {
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 4.5em;
    line-height: 1.5em;
}
.expanded-note {
    display: block;/* or inline-block */
    line-height: 1.5em;
}
div.dashed {
    border-top: 1px dashed #bbb;
    margin-top: 3;
    margin-bottom: 3;
}
.button {
    cursor: pointer;
    transition: .2s transform ease;
    border-radius: 3px;
}
.button:active {
    opacity: 0.5;
    transform: translateY(2px);
  }
.button:disabled {
    background-color: #bbbbbb;
}
.button:hover {
    transform: scale(1.1);
}
.button:hover:disabled {
    transform: none;
    cursor: not-allowed;
}
.practice-button {
    background-color: #007AFF;
    color: #FFFFFF;
    border: none;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.lesson-button {
    background-color: #33CC66;
    color: #FFFFFF;
    border: none;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.connect-button {
    background-color: #000000;
    color: #FFFFFF;
    border: none;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.disconnect-button {
    background-color: #FF0000;
    color: #FFFFFF;
    border: none;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.skip-button {
    background-color: #FFA500;
    color: #FFFFFF;
    border: none;
    text-align: center;
    display: inline-block;
    width: 70px;
    height: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
}
.vibrate {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.header-top-right {
    align-items: center;
    bottom: 8px;
    display: flex;
    justify-content: center;
    line-height: 15px;
    padding: 4px;
    position: absolute;
    right: 5px;
}
.header-top-left {
    align-items: center;
    bottom: 8px;
    display: flex;
    justify-content: center;
    left: 5px;
    line-height: 15px;
    padding: 4px;
    position: absolute;
}
.header-btn {
    background-color: transparent;
    border: none;
}
.header-txt {
    font-size: 15;
    padding: 5px;
    margin-bottom: 3px;
}
.spinning {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
.lock-button {
    background-color: #A52A2A;
    border: none;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
}
.unlock-button {
    background-color: #ffffff;
    border: 1px solid #999999;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
}
.learn-mode-practice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 106px;
    left: 32px;
    margin-left: -35px;
    z-index: 99;
}
.learn-mode-tutor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 106px;
    left: 32px;
    z-index: 99;
}
.practice-mode {
    height: 70px;
    width: 175px;
    margin-top: -10px;
}
.tutor-mode {
    height: 60px;
    width: 60px;
}
.dialog-icon .toolbox-icon {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d1dbe8;
    margin: 0px 4px;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.dialog-icon .toolbox-icon svg {
    fill: #5e6d7a;
}
.raisehand .toolbox-icon {
    background-color: #000000;
    border: 1px solid #555555;
    width: 48px;
    height: 48px;
}
.raisehand .toolbox-icon svg {
    fill: #fff;
}
.dialog-info-header {
    font-weight: bold;
    font-size: 14;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}
.dialog-info-text {
    font-size: 14;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}
.dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 250px;
}
.dialog-practice-mode {
    height: 105px;
    width: 263px;
}
.dialog-tutor-mode {
    height: 80px;
    width: 80px;
}
.dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.overflow-menu {
    font-size: 1.2em;
    list-style-type: none;
    background-color: initial;
   /** * Undo atlaskit padding by reducing margins. */
    margin: -16px -24px;
    padding: 4px 0;
}
.overflow-menu .overflow-menu-item {
    align-items: center;
    color: #b8c7e0;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 22px;
    padding: 5px 12px;
}
.overflow-menu .overflow-menu-item div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.overflow-menu .overflow-menu-item:hover {
    background-color: #313d52;
    color: #b8c7e0;
}
.overflow-menu .overflow-menu-item.unclickable {
    cursor: default;
}
.overflow-menu .overflow-menu-item.unclickable:hover {
    background: inherit;
}
.overflow-menu .overflow-menu-item.disabled {
    cursor: initial;
    color: #3b475c;
}
.overflow-menu .overflow-menu-item-icon {
    margin-right: 10px;
}
.overflow-menu .overflow-menu-item-icon i {
    display: inline;
    font-size: 24px;
}
.overflow-menu .overflow-menu-item-icon i:hover {
    background-color: initial;
}
.overflow-menu .overflow-menu-item-icon img {
    max-width: 24px;
    max-height: 24px;
}
.overflow-menu .overflow-menu-item-icon svg {
    fill: #b8c7e0 !important;
}
.overflow-menu .profile-text {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.menu-icon .toolbox-icon {
    border: none;
    margin-left: 6px;
    width: 12px;
    height: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.menu-icon .toolbox-icon svg {
    fill: #5e6d7a;
}
.learn-mode-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 106px;
    left: 32px;
    z-index: 99;
}
.practice-mode-timer {
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: rgb(0, 122, 255);
    display: flex;
    position: relative;
}
.timer-icon svg {
    fill: white;
    opacity: 0.25;
    height: 25px;
    width: 25px;
}
.timer-icon-solid svg {
    fill: white;
    opacity: 1;
    height: 25px;
    width: 25px;
}
.timer {
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
}
.timer .timer-text {
    font-size: 12;
    color: white;
}
.tutor-mode-timer {
    margin-top: 6px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #33CC66;
    display: flex;
    position: relative;
}
.grouped-rooms {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    background: #eee;
}
.grouped-rooms div * {
    overflow: hidden;
}
.single-room {
    width: 24.5%;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    float: none;
    padding: 0 5px;
    display: inline-block;
    zoom: 1;
}
.room-header {
    position: sticky;
    top: 5px;
    display: flex;
    flex-direction: 'row';
    background-color: #bbb;
    padding: 10px;
    opacity: 0.8;
    align-items: center;
}
.room-title {
    margin-left: 10px;
    font-size: 15px;
    color: #000;
    flex: 1;
}
.coffee-break .activated {
    font-weight: bold;
    font-size: 14;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 4px 8px;
    background-color: #33CC66;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15;
}
.coffee-break .deactivated {
    font-weight: bold;
    font-size: 14;
    color: rgb(255, 0, 0);
    text-align: center;
    margin-bottom: 15;
}
.dialog-coffee-break {
    height: 100px;
    width: 100px;
    transform: scale(2);
}
.coffee-break-button {
    background-color: #007AFF;
    border: none;
    text-align: center;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-size: 14;
}
.coffee-break-icon {
    font-size: 12;
}
.coffee-break-timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
}
.coffee-break-icon svg {
    fill: #fff;
    margin-right: 3px;
}
.blink {
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
