html body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  height: 100vh;
  width: 100vw;
  font-weight: 500;
  background-color: #fff !important;
}

.MuiTypography-root {
  font-family: 'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

#root {
  height: 100vh;
  width: 100vw;
}

.light {
  font-weight: 300 !important;
}

.bold {
  font-weight: 700 !important;
}

.smallFont {
  font-size: 11px;
}

.icon-sm {
  width: 16px;
  height: 16px;
}

.white-bg {
  background-color: #fff !important;
}

.white-bg > * {
  color: #000 !important;
}

.ghost-white-bg {
  background-color: #f6f8fa !important;
}

.white-smoke-bg {
  background-color: #e8eef3 !important;
}

.text-center {
  text-align: center !important;
}

/* START OF PROTECTED BLOCK --- PLEASE DO NOT MODIFY OR REMOVE EXISTING ONES */
.br-1 { border-radius: 4px !important; }
.br-2 { border-radius: 8px !important; }
.br-3 { border-radius: 16px !important; }
.br-4 { border-radius: 32px !important; }
.br-5 { border-radius: 64px !important; }
.br-6 { border-radius: 128px !important; }
.br-50 { border-radius: 50% !important; }
.br-100 { border-radius: 100% !important; }

.mt-1 { margin-top: 3px !important; }
.mt-2 { margin-top: 8px !important; }
.mt-3 { margin-top: 16px !important; }
.mt-4 { margin-top: 32px !important; }
.mt-5 { margin-top: 64px !important; }
.mt-6 { margin-top: 128px !important; }

.mb-1 { margin-bottom: 4px !important; }
.mb-2 { margin-bottom: 8px !important; }
.mb-3 { margin-bottom: 16px !important; }
.mb-4 { margin-bottom: 32px !important; }
.mb-5 { margin-bottom: 64px !important; }
.mb-6 { margin-bottom: 128px !important; }

.my-1 { margin-top: 3px !important; margin-bottom: 4px !important; }
.my-2 { margin-top: 8px !important; margin-bottom: 8px !important; }
.my-3 { margin-top: 16px !important; margin-bottom: 16px !important; }
.my-4 { margin-top: 32px !important; margin-bottom: 32px !important; }
.my-5 { margin-top: 64px !important; margin-bottom: 64px !important; }
.my-6 { margin-top: 128px !important; margin-bottom: 128px !important; }

.mx-1 { margin-left: 3px !important; margin-right: 4px !important; }
.mx-2 { margin-left: 8px !important; margin-right: 8px !important; }
.mx-3 { margin-left: 16px !important; margin-right: 16px !important; }
.mx-4 { margin-left: 32px !important; margin-right: 32px !important; }
.mx-5 { margin-left: 64px !important; margin-right: 64px !important; }
.mx-6 { margin-left: 128px !important; margin-right: 128px !important; }

/* padding */
.pt-1 { padding-top: 3px !important; }
.pt-2 { padding-top: 8px !important; }
.pt-3 { padding-top: 16px !important; }
.pt-4 { padding-top: 32px !important; }
.pt-5 { padding-top: 64px !important; }
.pt-6 { padding-top: 128px !important; }

.pb-1 { padding-bottom: 4px !important; }
.pb-2 { padding-bottom: 8px !important; }
.pb-3 { padding-bottom: 16px !important; }
.pb-4 { padding-bottom: 32px !important; }
.pb-5 { padding-bottom: 64px !important; }
.pb-6 { padding-bottom: 128px !important; }

.py-1 { padding-top: 3px !important; padding-bottom: 4px !important; }
.py-2 { padding-top: 8px !important; padding-bottom: 8px !important; }
.py-3 { padding-top: 16px !important; padding-bottom: 16px !important; }
.py-4 { padding-top: 32px !important; padding-bottom: 32px !important; }
.py-5 { padding-top: 64px !important; padding-bottom: 64px !important; }
.py-6 { padding-top: 128px !important; padding-bottom: 128px !important; }

.px-1 { padding-left: 3px !important; padding-right: 4px !important; }
.px-2 { padding-left: 8px !important; padding-right: 8px !important; }
.px-3 { padding-left: 16px !important; padding-right: 16px !important; }
.px-4 { padding-left: 32px !important; padding-right: 32px !important; }
.px-5 { padding-left: 64px !important; padding-right: 64px !important; }
.px-6 { padding-left: 128px !important; padding-right: 128px !important; }

.w-100 { width: 100%; }
.h-100 { height: 100%; }

.text-center { text-align: center; }
/* END OF PROTECTED BLOCK --- PLEASE DO NOT MODIFY OR REMOVE EXISTING ONES */

.fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.title {
  font-size: 24;
  font-weight: 700;
  margin-bottom: 20;
}

@media (min-width: 576px) {
  .fluid {
    max-width: 540px;
  }
  .title {
    font-size: 18;
    font-weight: 700;
    margin-bottom: 20;
  }  
}

@media (min-width: 768px) {
  .fluid {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .fluid {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .fluid {
    max-width: 1140px;
  }
}

/* START ---- Hide arrow/spinner for number text input */
/* Chrome, Safari, Edge, Opera */
.hide-spinner > * > input::-webkit-outer-spin-button ,
.hide-spinner > * > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-spinner > * >input[type=number] .hide-spinner {
  -moz-appearance: textfield;
}
/*   END ---- Hide arrow/spinner for number text input */

/* START customize scrollbar */
body > * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}
body > *::-webkit-scrollbar {
  width: 6px;
}
body > *::-webkit-scrollbar-track {
  background: transparent;
}
body > *::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 3px solid transparent;
}
/* END customize scrollbar */