.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

h2 {
  font-size: 40px;
}

.login-form-container {
  background-color: #fff;
  height: 100%;
  padding: 20px 50px;
}

@media (max-width: 576px) {
  .fill {
    display: none;
  }
}