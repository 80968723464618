/* grid layout */
.container {
  min-width: 1200px;
  min-height: 840px;
  display: grid;
  grid-gap: 1px;
  padding: 1px;
  background-color: lightgrey;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 64px 128px 128px 128px 128px 128px 128px;
  border-radius: 8px;
}
 
.item {
  background: #fff;
  display: flex;
}

.item:nth-of-type(1) {
  grid-column-start: 3;
  grid-column-end: 10;
  border-top-right-radius: 8px;
}

.item:nth-of-type(2) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #f6f8fa;
}

.item:last-child {
  border-bottom-right-radius: 8px;
}

.selected-day {
  width: 220px;
}

.top-left-panel {
  flex: 2;
  overflow-y: overlay;
}

.bottom-left-panel {
  flex: 1;
}
